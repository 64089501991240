'use client';

import cx from 'clsx';
import { useEffect, useState } from 'react';
import { ReactPlayerProps } from 'react-player';
import ReactPlayer from 'react-player/youtube';

import { SUPPORTED_PLAYERS, cleanYTUrl } from '@/helpers/videoHelpers';

import styles from './Video.module.scss';

interface VideoProps extends ReactPlayerProps {
    url: string;
    className?: string;
    disable?: boolean;
}

const BilibiliPlayer = ({
    url,
    disable,
    className,
}: {
    url: string;
    disable: boolean;
    className: string | undefined;
}) => {
    const id = new URL(url).pathname.replace('/video/', '');
    return (
        <iframe
            loading="lazy"
            className={cx(styles.video, className, {
                [styles.disabled]: disable,
            })}
            height="100%"
            src={`https://player.bilibili.com/player.html?aid=598443740&bvid=${id}&cid=772171712&page=1`}
            title="Bilibili Video Player"
            width="100%"
        />
    );
};

const YoukuPlayer = ({
    url,
    disable,
    className,
}: {
    url: string;
    disable: boolean;
    className: string | undefined;
}) => {
    const id = new URL(url).pathname.replace('/v_show/id_', '').replace('.html', '');
    return (
        <iframe
            loading="lazy"
            className={cx(styles.video, className, {
                [styles.disabled]: disable,
            })}
            height="100%"
            src={`https://player.youku.com/embed/${id}==`}
            title="Youku Video Player"
            width="100%"
        />
    );
};

export const Video = ({
    url,
    className,
    disable = false,
    light,
    controls = true,
    onPlay,
    onDuration,
    onEnded,
    onClickPreview,
    muted = true,
    playing = false,
    loop = false,
    style,
    playIcon,
}: VideoProps) => {
    const [type, setType] = useState('');

    useEffect(() => {
        const newType = SUPPORTED_PLAYERS.some((string) => url.includes(string))
            ? 'generic'
            : url.includes('bilibili')
              ? 'bilibili'
              : url.includes('youku')
                ? 'youku'
                : 'unsupported';

        setType(newType);
    }, [url]);

    return type === 'generic' ? (
        <ReactPlayer
            data-testid="video"
            className={cx(styles.video, className, disable && styles.disabled)}
            height={'100%'}
            url={cleanYTUrl(url)}
            width={'100%'}
            light={light}
            playIcon={playIcon}
            playing={playing}
            onPlay={onPlay}
            controls={controls}
            fallback={<></>}
            onClickPreview={onClickPreview}
            onDuration={onDuration}
            onEnded={onEnded}
            muted={muted}
            loop={loop}
            style={style}
        />
    ) : type === 'bilibili' ? (
        <BilibiliPlayer className={className} disable={disable} url={url} />
    ) : type === 'youku' ? (
        <YoukuPlayer className={className} disable={disable} url={url} />
    ) : (
        <></>
    );
};
