export const SUPPORTED_PLAYERS = [
    'dailymotion',
    'facebook',
    'kaltura',
    'mixcloud',
    'soundCloud',
    'streamable',
    'twitch',
    'vimeo',
    'wistia',
    'youtu',
];

export const cleanYTUrl = (url: string) => {
    const temp = new URL(url);
    let video = temp.searchParams.get('v');

    if (!video && temp.hostname.includes('youtu.be')) {
        video = temp.pathname.replaceAll('/', '');
    }

    return `https://www.youtube.com/watch?v=${video}`;
};

export const isSupportedVideo = (url: string) => {
    if (!url) return false;
    const temp = new URL(url);
    return SUPPORTED_PLAYERS.some((player) => temp.hostname.includes(player.toLowerCase()));
};
