'use client';

import Image from 'next/image';
import { signIn, signOut, useSession } from 'next-auth/react';

export const Profile = ({ strings, className }: { strings: any; className?: string }) => {
    const { data: session } = useSession();
    const svgPath = className?.includes('desktop') ? 'little-man' : 'little-man-green';

    return session?.user?.sub ? (
        <>
            <div className={className}>
                <Image
                    src={`/assets/images/${svgPath}.svg`}
                    alt="profile icon"
                    role="presentation"
                    width="20"
                    height="20"
                />
                <span>{session.user.nickname}</span>
            </div>
            <button className={className} onClick={() => signOut()}>
                {strings.logOutText || 'Logout'}
            </button>
        </>
    ) : (
        <button className={className} onClick={() => signIn('krafton')}>
            <Image
                src={`/assets/images/${svgPath}.svg`}
                alt="profile icon"
                role="presentation"
                width="20"
                height="20"
            />
            {strings.logInText || 'Login'}
        </button>
    );
};
