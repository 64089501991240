import Link from 'next/link';

import RedactedLogo from '@/assets/images/redacted-logo.svg';
import { Profile } from '@/features/authentication/components/profile/Profile.component';
import { SiteHeaderType } from '@/features/home/types';
import { LanguageSelector } from '@/features/site-language/components/language-selector/LanguageSelector.component';

import styles from './MobileNavigation.module.scss';

export const MobileNavigation = ({
    headerData,
    scrollToSection,
    currentLang,
    callback,
}: {
    headerData: SiteHeaderType;
    scrollToSection: (sectionId: string) => void;
    currentLang: string;
    callback: (openOrClose: boolean) => void;
}) => {
    return (
        <nav className={styles.mobileNavigation}>
            <Link
                href={`/${currentLang}/`}
                aria-label="Home"
                onClick={() => callback(false)}
                className={styles.logoLink}
            >
                <RedactedLogo className={styles.gameLogo} alt="redacted logo" />
            </Link>
            <div className={styles.innerWrapper}>
                <LanguageSelector
                    currentLang={currentLang}
                    labelClassName={styles.languageLabel}
                    arrowClassName={styles.languageArrow}
                    listClassName={styles.languageList}
                />
                <button onClick={() => scrollToSection('newsletter-section')} id="subscribe_mobile">
                    <p className={styles.subscribeText}>
                        {headerData?.subscribeText || 'Subscribe'}
                    </p>
                </button>
                <Link
                    href={`/${currentLang}/leaderboards`}
                    aria-label="Leaderboards"
                    onClick={() => callback(false)}
                >
                    <button id="leaderboards_header">
                        <p className={styles.subscribeText}>
                            {headerData?.leaderboardsText || 'Leaderboards'}
                        </p>
                    </button>
                </Link>
                <Profile strings={headerData?.loginText} className={styles.login} />
                <div className={styles.buttonWrapper}>
                    <button
                        className={styles.submit}
                        type="button"
                        onClick={() => scrollToSection('buy-section')}
                        id="preorder_mobile"
                    >
                        {headerData?.preorderText || 'Pre-Order'}
                    </button>
                </div>
            </div>
        </nav>
    );
};
