'use client';
import clsx from 'clsx';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';

import Close from '@/assets/images/modal-close.svg';
import { TextBlock } from '@/components/text-block/TextBlock.component';
import { BANNER_LS_KEY } from '@/constants';
import { useGeolocation } from '@/stores/geolocation.store';

import { SiteBannerType } from '../../types/SiteBanner';

import styles from './SiteBanners.module.scss';

type SiteBannersProps = {
    banners: SiteBannerType[];
};

export const SiteBanners = ({ banners }: SiteBannersProps) => {
    const { getGeolocation } = useGeolocation();

    const [filteredBanners, setFilteredBanners] = useState<SiteBannerType[]>([]);

    const handleDismiss = (id: number) => {
        const tempBanners = [...filteredBanners];

        const dismissedBanners = new Set(
            JSON.parse(localStorage.getItem(BANNER_LS_KEY) || '[]')
        ).add(id);

        localStorage.setItem(BANNER_LS_KEY, JSON.stringify([...dismissedBanners]));

        const banner = tempBanners.find((banner: SiteBannerType) => banner.id === id);

        if (banner) banner.hidden = true;

        setFilteredBanners([...tempBanners]);
    };

    useEffect(() => {
        if (!getGeolocation) return;

        getGeolocation().then(({ country_code }: { country_code: string }) => {
            const dismissedBanners = JSON.parse(localStorage.getItem(BANNER_LS_KEY) || '[]');

            setFilteredBanners(
                banners
                    .filter((ban: SiteBannerType) => {
                        return (
                            !dismissedBanners.includes(ban.id) &&
                            (ban.regions.length === 0 || ban.regions.includes(country_code))
                        );
                    })
                    .sort((a) => (a.type === 'notice' ? 1 : -1))
            );
        });
    }, [banners, getGeolocation]);

    return filteredBanners && filteredBanners.length > 0 ? (
        <ul className={styles.banners}>
            {filteredBanners.map((banner) =>
                !banner.hidden ? (
                    <li
                        key={banner.id}
                        className={clsx(styles.banner, {
                            [styles.alertBanner]: banner.type === 'alert',
                            [styles.noticeBanner]: banner.type === 'notice',
                        })}
                        data-testid="site-banner"
                    >
                        <div className={styles.textBtnWrapper}>
                            <div
                                className={clsx(styles.text, {
                                    [styles.clickable]: banner.heading_link !== '',
                                })}
                            >
                                {banner.heading_link && (
                                    <Link
                                        className={styles.headingLink}
                                        href={banner.heading_link || '#'}
                                        onClick={() => handleDismiss(banner.id)}
                                        target="_blank"
                                    />
                                )}
                                <div className={styles.headingText}>{banner.heading_text}</div>
                                {banner.details !== '' && (
                                    <TextBlock
                                        className={styles.details}
                                        content={banner.details}
                                    />
                                )}
                            </div>
                            {banner.button_text && (
                                <button
                                    className={styles.cta}
                                    onClick={() => handleDismiss(banner.id)}
                                >
                                    <Link href={banner.button_link || '#'}>
                                        {banner.button_text}
                                    </Link>
                                </button>
                            )}
                        </div>
                        <button
                            className={styles.hide}
                            onClick={() => handleDismiss(banner.id)}
                            data-testid="dismiss-banner"
                        >
                            <Close className={styles.closeIcon} />
                        </button>
                    </li>
                ) : (
                    <Fragment key={banner.id} />
                )
            )}
        </ul>
    ) : (
        <></>
    );
};
