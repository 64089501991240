'use client';

import cx from 'clsx';
import { useRouter } from 'next/navigation';
import { useEffect, useId, useMemo, useRef, useState } from 'react';

import ArrowSVG from '@/assets/images/arrow.svg';
import GlobeIcon from '@/assets/images/globe-icon.svg';

import { defaultLanguage } from '../../helpers/defaultLanguages';
import { useLanguage } from '../../store/language.store';

import styles from './LanguageSelector.module.scss';

export const LanguageSelector = ({
    className,
    labelClassName,
    arrowClassName,
    listClassName,
    currentLang,
}: {
    className?: string;
    labelClassName?: string;
    arrowClassName?: string;
    listClassName?: string;
    currentLang: string;
}) => {
    const uid = useId();
    const router = useRouter();
    const container = useRef<HTMLDivElement | null>(null);
    const list = useRef<HTMLDivElement | null>(null);

    const { language, languages, supported, setLanguage } = useLanguage();

    const [open, setOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);

    const options = useMemo(
        () =>
            languages.reduce((langs: Array<any>, lang: any) => {
                if (!supported.includes(lang.iso)) return langs;

                return (langs = [
                    ...langs,
                    {
                        label: lang.label,
                        value: lang.iso,
                    },
                ]);
            }, []),
        [languages, supported]
    );

    const defaultOption =
        options.find((option) => option.value.toLowerCase() === currentLang.toLowerCase()) ||
        options.find((option) => option.value === 'en') ||
        options[0];

    const handleOpenClick = () => {
        const max = window.innerHeight - (list.current?.getBoundingClientRect().top || 0) - 20;
        setMaxHeight(max);
        setOpen(!open);
    };

    const handleLanguageSelect = (option: { label: string; value: string }) => {
        const newLanguage = languages.find((lang) => lang.iso === option.value) || defaultLanguage;

        // The current URL path can sometimes get mismatched with the expected language path.
        // => Find any matching path from standard URLs and replace it.
        const newPath = window.location.href
            .replace(window.location.origin, '')
            .replace(
                languages
                    .map((lang) => lang.path)
                    .find((path) =>
                        window.location.href.replace(window.location.origin, '').includes(path)
                    ) || '',
                newLanguage.path || ''
            );

        setLanguage(newLanguage);
        setOpen(false);

        router.push(newPath);

        // => Focus the container to allow keyboard navigation.
        container.current?.focus();

        // The layout.tsx doesn't update automatically on language change.
        // => Update the HTML language and direction to new values manually.
        document.documentElement.lang = newLanguage.iso;
        document.documentElement.dir = newLanguage.iso === 'ar' ? 'rtl' : 'ltr';
    };

    useEffect(() => {
        const close = (Event: MouseEvent) =>
            !(Event.target as HTMLElement)?.closest(`.${styles.container}`) && setOpen(false);

        document.addEventListener('click', close);
        return () => document.removeEventListener('click', close);
    }, [container]);

    return options.length < 2 ? (
        <></>
    ) : (
        <div
            className={cx(styles.container, className)}
            ref={container}
            style={{ ['--max-height' as string]: `${maxHeight}px` }}
        >
            <button
                aria-activedescendant={open ? `${defaultOption.label}-${uid}` : ''}
                aria-controls={`list-${uid}`}
                aria-expanded={open}
                aria-haspopup="listbox"
                className={cx(styles.label, labelClassName)}
                id={`label-${uid}`}
                onClick={handleOpenClick}
                role="combobox"
                tabIndex={0}
                title="Choose your language"
            >
                <GlobeIcon aria-label="globe icon" className={styles.globeIcon} />
                <span className={styles.text}>{defaultOption.label}</span>
                <ArrowSVG
                    aria-label="down arrow icon"
                    className={cx(styles.arrow, arrowClassName)}
                />
            </button>
            <div
                aria-labelledby={`label-${uid}`}
                className={cx(styles.list, listClassName, { [styles.open]: open })}
                id={`list-${uid}`}
                ref={list}
                role="listbox"
                tabIndex={-1}
            >
                {options.map(({ label, value }) => {
                    return (
                        <button
                            key={`${label}-${value}`}
                            aria-selected={value === language}
                            className={cx(styles.item, {
                                [styles.selected]:
                                    value.toLowerCase() === currentLang.toLowerCase(),
                            })}
                            id={`${label}-${uid}`}
                            onClick={() => handleLanguageSelect({ label, value })}
                            role="option"
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
