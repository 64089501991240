import { create } from 'zustand';

import { GEOIP_URL } from '@/constants';

interface GeoLocationState {
    ip_address: string;
    continent_code: string;
    country: string;
    country_code: string;
    country_is_eu: boolean;
    timezone: {
        name: string;
        abbreviation: string;
        gmt_offset: string;
        current_time: string;
        is_dst: boolean;
    };
    getGeolocation: any;
}

const initialState = {
    ip_address: '',
    continent_code: '',
    country: '',
    country_code: '',
    country_is_eu: false,
    timezone: {
        name: '',
        abbreviation: '',
        gmt_offset: '',
        current_time: '',
        is_dst: false,
    },
};

export const useGeolocation = create<GeoLocationState>((set, get) => ({
    ...initialState,

    getGeolocation: async () => {
        const current = get();

        if (current.ip_address !== '') return current;

        const data = await fetch(GEOIP_URL || '')
            .then((response) => response.json())
            .catch((error) => console.error('ERROR: Unable to get Geolocation data:', error));

        const newState = {
            ip_address: data.ip_address,
            continent_code: data.continent_code.toLowerCase(),
            country: data.country.toLowerCase(),
            country_code: data.country_code.toLowerCase(),
            country_is_eu: data.country_is_eu,
            timezone: data.timezone,
        };

        set(newState);

        return newState;
    },
}));
