import Cookies from 'js-cookie';
import { create } from 'zustand';

import { I18N_COOKIE } from '@/constants';

import { defaultLanguage, defaultLanguages } from '../helpers/defaultLanguages';
import { LanguageStoreType, LanguageType, LanguagesType } from '../types';

const initialState = {
    language:
        defaultLanguages.find((lang) => lang.iso === Cookies.get(I18N_COOKIE)) || defaultLanguage,
    languages: defaultLanguages,
    supported: defaultLanguages.map((language) => language.iso),
};

export const useLanguage = create<LanguageStoreType>((set, get) => ({
    ...initialState,

    setLanguage: (language: LanguageType) => {
        Cookies.set(I18N_COOKIE, language.iso);
        set({ language });
    },
    setLanguages: (languages: LanguagesType) => set({ languages }),
    setSupported: (languages: LanguagesType) => {
        const currentLang = get().language;

        set({ languages });

        // Reset language if it's not supported at this time.
        if (!languages.some((lang) => lang.iso === currentLang.iso)) {
            set({ language: defaultLanguage });
        }
    },
}));
