export const domPurifyConfig = {
    ADD_TAGS: ['iframe', 'wbr'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    FORBID_TAGS: ['style', 'span', 'div'],
    FORBID_ATTR: ['style'],
    RETURN_DOM: true,
};

export const processMarkup = ({
    markup,
    imageOptimized,
}: {
    markup: HTMLElement;
    imageOptimized: boolean;
}) => {
    if (!imageOptimized) {
        markup.querySelectorAll('img').forEach((img) => {
            img.removeAttribute('sizes');
            img.removeAttribute('srcset');
        });
    }

    return markup.innerHTML;
};
